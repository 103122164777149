import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getChartDataAPI } from 'api/getChartDataAPI';

const initialState = {
    loading: false,
    error: false,
    errorObj: {} as any,
    chartData: [] as any,
};

export const fetchChartData = createAsyncThunk(
    'chart/fetchChartData',
    async (portalObj: {
        portalId: string;
        orgId: string;
        activeTab: string;
        type: string;
        startDate: Date;
        EndDate: Date;
    }) => {
        return await getChartDataAPI(portalObj);
    },
);

const chartSlice = createSlice({
    name: 'dashboardChart',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchChartData.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchChartData.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.chartData = action?.payload?.data?.data;
            })
            .addCase(fetchChartData.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
            });
    },
});
export default chartSlice.reducer;
