import { apiGet } from './axios';
import { ENDPOINT_DEV } from './configs';

export const getChartDataAPI = async ({
    orgId,
    portalId,
    activeTab,
    type,
    startDate,
    endDate,
}: {
    orgId: string;
    portalId: string;
    activeTab: string;
    type: string;
    startDate?: Date;
    endDate?: Date;
}) => {
    let url = `${ENDPOINT_DEV}analytics/${orgId}/portal/${portalId}/${type}/${activeTab}`;
    if (type === 'custom' && startDate && endDate) {
        url += `?${startDate ? `startDate=${startDate.getTime()}` : ''}${startDate && endDate ? '&' : ''}${
            endDate ? `endDate=${endDate.getTime()}` : ''
        }`;
    }
    return await apiGet(url);
};
